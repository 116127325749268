<template>
  <v-card>
      <router-link :to="`prediction/${fileID['id']}`">
        <img :src="`${fileID['location']}`" width="100%"/>
      </router-link>
      <v-card-title primary-title>
        {{ fileID['status'] }}
      </v-card-title>
  
      <v-card-actions>
        <DeleteUpload 
          :fileID=fileID />
      </v-card-actions>
  </v-card>
</template>

<script>
import DeleteUpload from "@/components/DeleteUpload";
import { DELETE_UPLOAD } from "../store/actions.type";
export default {
  name: "SegmentedItem",
  components: {
    DeleteUpload
  },
  props: {
    fileID: { type: Object, required: true }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    deleteFile() {
      console.log('delete called')
      this.$store.dispatch(DELETE_UPLOAD, this.fileID);
    }
  }
};
</script>