<template>
  <div><FilesList type="all" /></div>
</template>
<script>
import FilesList from "@/components/FilesList";
export default {
  name: "dashboard",
  components: {
    FilesList
  }
};
</script>