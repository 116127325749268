<template>
  <div>
      <FileUpload/>
      <PayButton 
        :balance=balance />

    <div class="card">
      <div class="card-header">List of Files</div>
      <v-row>
        <v-col 
          xs="6"
          sm="6"
          md="2"
          cols="6"
          v-for="(f, index) in files"
                :key="index"
        >
                <FileListItem
                  :fileID=f />

        </v-col>
      </v-row>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { FETCH_FILES, FETCH_BALANCE, SELECT_FILE, UPLOAD_SELECTED_FILE } from "../store/actions.type";
import FileListItem from "@/components/FileListItem";
import FileUpload from "@/components/FileUpload";
import PayButton from "@/components/PayButton";

export default {
  name: "FilesList",
  components: {
    FileListItem,
    FileUpload,
    PayButton
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(["files", "balance", "selectedFiles", "message", "presignedURL"])
  },
  methods: {
    fetchFiles() {
      this.$store.dispatch(FETCH_FILES);
    },
    fetchBalance() {
      this.$store.dispatch(FETCH_BALANCE);
    },
    selectFile(event) {
      this.$store.dispatch(SELECT_FILE, event);
    },
    upload() {
      this.$store.dispatch(UPLOAD_SELECTED_FILE);
      this.fetchFiles();
    }
  },
  mounted() {
    this.fetchFiles();
    this.fetchBalance();
  }
};
</script>












