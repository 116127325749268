<template>
    <v-card>
      <v-card-title primary-title>
        {{ fileID['status'] }}
      </v-card-title>
  
      <v-card-actions>
        <DeleteUpload 
          :fileID=fileID />
        <FileUpload
          :fileID=fileID />
      </v-card-actions>
  </v-card>
</template>

<script>
import DeleteUpload from "@/components/DeleteUpload";
import FileUpload from "@/components/FileUpload";

export default {
  name: "FileListItem",
  components: {
    FileUpload,
    DeleteUpload
  },
  props: {
    fileID: { type: Object, required: true }
  },
  computed: {
  },
  mounted() {
  }
};
</script>