import Vue from "vue";
import Vuex from "vuex";

import dashboard from "./dashboard.module";
import predictions from "./prediction.module";
import login from "./login.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    dashboard,
    predictions, 
    login
  }
});