import { CHECK_USER, LOGIN, LOGIN_MAGIC_LINK,  LOGOUT, SIGNUP, REQUEST_RESET_PASSWORD, UPDATE_PASSWORD } from "./actions.type";
import { supabase } from "../supabase";

import {
  SET_LOGIN,
  SET_USER,
  SET_SIGNUP,
  SET_REQUEST_RESET_PASSWORD,
  SET_UPDATE_PASSWORD,
  SET_LOGIN_FAILED,
} from "./mutations.type";


const state = {
  user: null,
  profile: null,
  login: null,
  loggedIn: false,
  signup: false,
  passwordReset: null,
  message: "",
  updatePassword: null,
  loginErrorMessagen: "",
};

const getters = {
  getLogin(state) {
    if (supabase.auth.session() != null) {
      state.loggedIn = true
    } else {
      state.loggedIn = false
    }
    return state.loggedIn;
  },
  getSignup(state) {
    return state.signUp;
  },
  getReset(state) {
    return state.passwordReset;
  },
  getMessage(state) {
    return state.message;
  },
  getLoginErrorMessage(state) {
    return state.loginErrorMessage;
  },
  getUpdatePassword(state) {
    return state.updatePassword;
  }
};

const actions = {
  async [CHECK_USER]({ commit }) {
      const user = supabase.auth.user()
      if (user) {
        commit(SET_LOGIN, true)
        commit(SET_USER, user)
      } else {
        commit(SET_LOGIN, false)
        commit(SET_USER, null)
      }
  },
  async [SIGNUP]({ commit }, formData) {
    let {user, error} = supabase.auth.signUp({
      email: formData.email,
      password: formData.password
    },{
      //redirectTo: 'http://10.2.0.205:8081/dashboard'
      redirectTo: 'https://minifigfinder.com/dashboard'
    });
    if (error) {
      return false;
    } else {
      commit(SET_SIGNUP);
      commit(SET_USER, user)
    }
  },
  async [LOGIN]({ commit }, formData) {
    try {
      const { error, user } = await supabase.auth.signIn({
        email: formData.email,
        password: formData.password,
      },{
        //redirectTo: 'http://10.2.0.205:8081/dashboard'
        redirectTo: 'https://minifigfinder.com/dashboard'
      });
      if (user) {
        commit(SET_LOGIN, true);
      }
      if (error) {
        commit(SET_LOGIN_FAILED);
      }
    } catch (error) {
      console.log("here");
      console.log(error);
      commit(SET_LOGIN_FAILED);
    }
    
    
  },
  async [LOGIN_MAGIC_LINK]({ commit }, formData) {
    try {
      const { error, user } = await supabase.auth.signIn({
        email: formData.email,
      },{
        //redirectTo: 'http://10.2.0.205:8081/dashboard'
        redirectTo: 'https://minifigfinder.com/dashboard'
      });
      console.log(user);
      console.log(error);
      if (user) {
        commit(SET_LOGIN, true);
      }
      if (error) {
        commit(SET_LOGIN_FAILED);
      }
    } catch (error) {
      commit(SET_LOGIN_FAILED);
    }
    
    
  },
  async [REQUEST_RESET_PASSWORD]({ commit }, formData) {
      return await supabase.auth.api.resetPasswordForEmail(formData.email, {
        //redirectTo: 'http://10.2.0.205:8081/resetPassword'
        redirectTo: 'https://minifigfinder.com/resetPassword'
      })
        .then(({ data }) => {
          if (data == null) {
            commit(SET_REQUEST_RESET_PASSWORD, false);
            return false;
          } else {
            commit(SET_REQUEST_RESET_PASSWORD, true);
            return true;
          }
        })
        .catch(error => {
          commit(SET_REQUEST_RESET_PASSWORD, false);
          console.log(error);
          return false;
        })
  },
  async [UPDATE_PASSWORD]({ commit }, formData) {
    let token = supabase.auth.session();
    return await supabase.auth.api.updateUser(
      token.access_token,
      { 
        password: formData.password 
      })
      .then(({ data }) => {
        commit(SET_UPDATE_PASSWORD, true);
        console.log(data)
      })
      .catch(error => {
        commit(SET_UPDATE_PASSWORD, false);
        console.log(error)
      });

  },
  async [LOGOUT]({ commit }) {
    let {error} = await supabase.auth.signOut();
    if (error) {
      commit(SET_LOGIN, true)
    } else {
      commit(SET_LOGIN, false)
    }
  }
};

const mutations = {
  [SET_LOGIN](state, loggedIn) {
    state.loggedIn = loggedIn;
    state.loginErrorMessage = "";
  },
  [SET_LOGIN_FAILED](state) {
    state.loginErrorMessage = "Invalid username or password";
  },
  [SET_USER](state, user) {
    state.user = user;
  },
  [SET_SIGNUP](state) {
    state.signup = true;
  },
  [SET_UPDATE_PASSWORD](state, updatePassword) {
    if (updatePassword) {
      state.updatePassword = updatePassword;
      state.message = "Successfully updated Password"
    } else {
      state.updatePassword = true;
      state.message = "Failed to reset password";
    }
    state.updatePassword = updatePassword;
  },
  [SET_REQUEST_RESET_PASSWORD](state, passwordReset) {
    if (passwordReset) {
      state.passwordReset = passwordReset;
      state.message = "Please check your email for a reset password link";
    } else {
      state.passwordReset = true;
      state.message = "Failed to send password reset";
    }
    
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};