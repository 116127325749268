<template>
  <v-main class="forgot-password mt-5">
    <v-container
        fill-height
        fluid
    >
      <v-row
          justify="center"
          align="center"
      >
        <v-card 
            class="elevation-10"
            max-width="400px"
            color="primary"

            dark
        >
          <v-row
              class="mx-10"
          >
            <v-col
                cols="12"
                md="12"
            >
            </v-col>
              <div :class="{ 'reset-form': !showResetForm }">
                <v-form 
                    v-if="showResetForm"
                    @submit.prevent="handleReset"
                    ref="resetForm"
                >
                  <div v-if="!getReset">
                  <v-card-title class="mt-5 ml-n7">
                    Reset Password
                  </v-card-title>
                    <v-row>
                        <v-col
                            cols="12"
                            md="12"
                        >
                            <v-text-field
                                label="Email"
                                name="email"
                                append-icon="mdi-email"
                                dense
                                outlined
                                filled
                                type="text"
                                color="white"
                                v-model="formData.email"
                                :rules="[rules.required, rules.email]"
                                :error-messages="getReset ? 'Failed to send password reset' : ''"
                            />
                        </v-col>


                          <v-col
                              cols="12"
                              md="12"
                          >
                            <div>
                                  <v-btn class="mb-10" block rounded color="white" light type="submit">Forgot Password</v-btn>
                              </div>
                              
                          </v-col>
                          
                          
                          
                    </v-row>
                    </div>
                    <div v-else>
                      <v-card-text class="font-weight-bold mt-5 mb-5">
                        {{ getMessage }}
                      </v-card-text>
                    </div>
                </v-form>
                <v-form 
                  v-else 
                  @submit.prevent="handleUpdatePassword"
                  ref="updatePasswordForm"
                >
                  <div v-if="!getUpdatePassword">
                  <v-card-title class="mt-5 ml-n7">
                    Update Password
                  </v-card-title>
                  <v-row>
                      <v-col
                          cols="12"
                          md="12"
                      >
                          <v-text-field
                              label="Password"
                              name="password"
                              append-icon="mdi-lock"
                              dense
                              outlined
                              filled
                              type="password"
                              color="white"
                              v-model="formData.password"
                              :rules="[rules.required]"
                              :error-messages="getUpdatePassword ? 'Incorrect email or password' : ''"
                          />
                      </v-col>
                      <v-col
                              cols="12"
                              md="12"
                          >
                              <div class="text-end">
                                  <v-btn block rounded color="white" light type="submit">Update Password</v-btn>
                              </div>
                          </v-col>
                          
                      <v-col
                              cols="12"
                              md="12"
                          >
                            <div class="text-start">
                                  <v-btn class="mb-5" plain color="white" @click="toggleForm" light type="submit">Back to SignIn</v-btn>
                              </div>
                              
                          </v-col>
                          
                  </v-row>
                  </div>
                    <div v-else>
                      <v-card-text class="font-weight-bold mt-5 mb-5">
                        {{ getMessage }}
                      </v-card-text>
                    </div>
                </v-form>
              </div>
          </v-row>
        </v-card>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>

    import { mapGetters } from 'vuex'
    import { REQUEST_RESET_PASSWORD, UPDATE_PASSWORD } from "../store/actions.type";
    import { supabase } from "../supabase";


    export default {
        name: 'ForgotPassword',

        data () {
            return {
                alignments: [
                    'start',
                    'center',
                    'end',
                ],
                formData: {
                    email: ''
                },
                rules: {
                    required: value => !!value || 'Required.',
                    counter: value => value.length <= 20 || 'Max 20 characters',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                },
                showResetForm: true,
                submitted: false,
                authListener: null,
            }
        },
        computed:{
            ...mapGetters(["getReset", "getMessage", "getUpdatePassword"])
        },
        mounted(){
            const { data: authListener } = supabase.auth.onAuthStateChange((event) => {
              if (event == "PASSWORD_RECOVERY") {
                this.toggleResetForm()
              }
            });

            this.authListener = authListener;
        },
        beforeUnmount() {
          this.authListener?.unsubscribe();
        },
        methods: {
            toggleResetForm() {
              this.showResetForm = !this.showResetForm;
            },
            handleReset () {
                try{
                    if(this.$refs.resetForm.validate()){
                        this.$store.dispatch(REQUEST_RESET_PASSWORD, this.formData);
                      }
               
                }catch(error){
                    this.$refs.resetForm.validate()
                }
            },
            handleUpdatePassword() {
              try {
                if(this.$refs.updatePasswordForm.validate()){
                  this.$store.dispatch(UPDATE_PASSWORD, this.formData);
                }
              } catch(error) {
                this.$refs.updatePasswordForm.validate()
              }
            }
            
        }
        
    }
</script>