<template>
  <div>
        <v-file-input
          label="Add your files" 
          accept="image/*"
          prepend-icon="mdi-camera"
          @change="selectFile"
        ></v-file-input>
        <v-progress-linear
          color="light-blue"
          height="10"
          :value="`${progress}`"
          striped
          v-show="isUploading"
        ></v-progress-linear>

  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { SELECT_FILE } from "../store/actions.type";

export default {
  name: "FileUpload",
  components: {
  },
  data() {
    return {

    };
  },
  props: {
    fileID: {
      required: false
    }
  },
  computed: {
    ...mapGetters(["files", "selectedFiles", "message", "presignedURL", "progress", "isUploading"])
  },
  methods: {
    selectFile(event) {
      this.$store.dispatch(SELECT_FILE, {upload: this.fileID, file: event});
    },
  },
  mounted() {
    console.log(this.progress)
  }
};
</script>









