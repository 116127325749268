<template>
  <v-btn
    text
    v-if="getLogin"
    @click="logout"
    to="/login"
  >
    Log Out
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "../store/actions.type";
export default {
  name: "LogoutButton",
  computed: {
    ...mapGetters(["getLogin"])
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT);
    },
  },
};
</script>