<template>
  <ul class="navbar-nav mr-auto">
    <v-btn text to="/">Home</v-btn>|
    <v-btn text to="/dashboard">Minifig Identifier</v-btn>


  </ul>
</template>

<script>
export default {
  name: "MainNav",
};
</script>