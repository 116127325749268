<template>
    <CreatedItem 
      :fileID=fileID
      v-if="!segmented" />
    <SegmentedItem
      :fileID=fileID
      v-else />
</template>

<script>
import SegmentedItem from "@/components/SegmentedItem";
import CreatedItem from "@/components/CreatedItem";
export default {
  name: "FileListItem",
  components: {
    SegmentedItem,
    CreatedItem
  },
  props: {
    fileID: { type: Object, required: true }
  },
  computed: {
    segmented() {
      if (this.fileID['status'] == 'SEGMENTED') {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
  }
};
</script>