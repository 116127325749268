<template>
  <v-btn text to='/login' v-if="!getLogin">
    Log In
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LoginButton",
  methods: {
  },
  computed: {
    ...mapGetters(["getLogin"])
  },
};
</script>