<template>
  <div class="card">
    <div class="card-header">List of Predictions</div>
    <v-expansion-panels>
      <v-expansion-panel 
        v-for="(segmentations, predictionId) in getPredictions" 
        :key="predictionId"
      >
        <v-expansion-panel-header>
          <v-row>
          <v-col
            cols="6"
            xs="6"
            sm="6"
            md="2">
            <img :src="`${segmentations['location']}`" width="100%"/>
          </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-for="(pred, idx) in segmentations['classifications']"
          :key="idx"
        >
          <v-container fluid>
          <v-row>
            <v-col
             xs="6"
             sm="6"
             md="2"
             cols="6">
                    <PredictionListItem
                      :prediction=pred
                      :classificationID=predictionId
                      :index=idx />
                  </v-col>
          </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>

import { mapGetters } from "vuex";
import { FETCH_PREDICTIONS } from "../store/actions.type";
//import PredictionPreview from "./PredictionPreview";
import PredictionListItem from "./PredictionListItem";

export default {
  name: "PredictionList",
  components: {
//    PredictionPreview
    PredictionListItem,
  },
  data() {
    return {

    };
  },
  props: {
    uploadID: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters(["getPredictions"])
  },
  methods: {
    fetchPredictions() {
      this.$store.dispatch(FETCH_PREDICTIONS, this.uploadID)
    },
  },
  mounted() {
    this.fetchPredictions();
  }
};
</script>









