<template>
  <div>
  <v-card
    class="overflow-hidden"
  >
    <v-app-bar
      dark
      color="primary"
  
    >
          <MainNav />
          <AuthNav />
    </v-app-bar>
  </v-card>
  <v-spacer></v-spacer>
  </div>

</template>

<script>
import MainNav from "@/components/MainNav";
import AuthNav from "@/components/AuthNav";
export default {
  name: "NavBar",
  components: { AuthNav, MainNav },
};
</script>