<template>
  <v-main class="login-page mt-5">
    <v-container
        fill-height
        fluid
    >
      <v-row
          justify="center"
          align="center"
      >
        <v-card 
            class="elevation-10"
            max-width="400px"
            color="primary"

            dark
        >
          <v-row
              class="mx-10"
          >
            <v-col
                cols="12"
                md="12"
            >
            </v-col>
              <div :class="{ 'signup-form': !showLoginForm }">
                <v-form 
                    v-if="showLoginForm" 
                    @submit.prevent="handleLogin"
                    ref="loginForm"
                >
                  <div>
                  <v-card-title class="mt-5 ml-n7">
                    Sign In
                  </v-card-title>
                    <v-row>
                        <v-col
                            cols="12"
                            md="12"
                        >
                            <v-text-field
                                label="Email"
                                name="email"
                                append-icon="mdi-email"
                                dense
                                outlined
                                filled
                                type="text"
                                color="white"
                                v-model="formData.email"
                                :rules="[rules.required, rules.email]"
                                :error-messages="getLogin ? 'Incorrect email or password' : ''"
                            />
                            <v-text-field
                              label="Password"
                              name="password"
                              append-icon="mdi-lock"
                              dense
                              outlined
                              filled
                              type="password"
                              color="white"
                              v-model="formData.password"
                              :rules="[rules.required]"
                              :error-messages="getLogin ? 'Incorrect email or password' : ''"
                          />
                            <div class="text-end">
                                  <v-btn x-small plain color="white" to="resetPassword" light type="submit">Forgot your password?</v-btn>
                              </div>
                        </v-col>
                          <v-col
                              cols="12"
                              md="12"
                          >
                            <div>
                                  <v-btn block rounded color="white" light type="submit">Sign In</v-btn>
                              </div>
                              <div class="red--text">{{ getLoginErrorMessage }}</div>
                              
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <div>
                              OR
                            </div>
                          </v-col>

                          <v-col
                              cols="12"
                              md="12"
                          >
                              <div class="text-end">
                                  <v-btn block rounded class="mb-10" color="white" @click="toggleForm" light type="submit">SIGN UP</v-btn>
                              </div>
                          </v-col>
                          
                          
                    </v-row>
                    </div>
                    
                </v-form>
                <v-form 
                  v-else 
                  @submit.prevent="handleSignup"
                  ref="signUpForm"
                >
                  <div v-if="!submitted">
                  <v-card-title class="mt-5 ml-n7">
                    Get Started
                  </v-card-title>
                  <v-row>
                      <v-col
                          cols="12"
                          md="12"
                      >
                          <v-text-field
                              label="Email"
                              name="email"
                              append-icon="mdi-email"
                              dense
                              outlined
                              filled
                              type="text"
                              color="white"
                              v-model="formData.email"
                              :rules="[rules.required, rules.email]"
                              :error-messages="getSignup ? 'Incorrect email or password' : ''"
                          />
                          <v-text-field
                              label="Password"
                              name="password"
                              append-icon="mdi-lock"
                              dense
                              outlined
                              filled
                              type="password"
                              color="white"
                              v-model="formData.password"
                              :rules="[rules.required]"
                              :error-messages="getSignup ? 'Incorrect email or password' : ''"
                          />
                      </v-col>
                      <v-col
                              cols="12"
                              md="12"
                          >
                              <div class="text-end">
                                  <v-btn block rounded color="white" light type="submit">SignUp</v-btn>
                              </div>
                          </v-col>
                          
                      <v-col
                              cols="12"
                              md="12"
                          >
                            <div class="text-start">
                                  <v-btn class="mb-5" plain color="white" @click="toggleForm" light type="submit">Back to SignIn</v-btn>
                              </div>
                              
                          </v-col>
                          
                  </v-row>
                  </div>
                    <div v-else>
                      <v-card-text class="font-weight-bold mt-5 mb-5">
                        Please check your email for a verification link
                      </v-card-text>
                    </div>
                </v-form>
              </div>
          </v-row>
        </v-card>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>

    import { mapGetters } from 'vuex'
    import { LOGIN, SIGNUP, CHECK_USER, REQUEST_RESET_PASSWORD } from "../store/actions.type";
    import { supabase } from "../supabase";


    export default {
        name: 'Login',

        data () {
            return {
                alignments: [
                    'start',
                    'center',
                    'end',
                ],
                formData: {
                    email: ''
                },
                rules: {
                    required: value => !!value || 'Required.',
                    counter: value => value.length <= 20 || 'Max 20 characters',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                },
                showLoginForm: true,
                showResetForm: false,
                submitted: false,
                authListener: null,
            }
        },
        computed:{
            ...mapGetters(["getLogin", "getLoginErrorMessage"])
        },
        mounted(){
            const { data: authListener } = supabase.auth.onAuthStateChange(() =>
              this.checkLogin()
            );

            this.authListener = authListener;
            this.checkLogin();
        },
        beforeUnmount() {
          this.authListener?.unsubscribe();
        },
        methods: {
            checkLogin() {
              this.$store.dispatch(CHECK_USER);
              if (this.getLogin == true) {
                this.$router.push(this.$route.query.redirect || '/')
              }
            },
            handleLogin () {
                try{
                    if(this.$refs.loginForm.validate()){
                        this.$store.dispatch(LOGIN, this.formData)
                        this.submitted = true;
                    }
                } catch(error) {
                    this.$refs.loginForm.validate()
                }
            },
            toggleForm() {
              this.showLoginForm = !this.showLoginForm;
            },
            toggleResetForm() {
              this.showResetForm = !this.showResetForm;
            },
            handleSignup () {
                try{
                    if(this.$refs.signUpForm.validate()){
                        this.$store.dispatch(SIGNUP, this.formData)
                        this.submitted = true;
                      }
               
                }catch(error){
                    this.$refs.loginForm.validate()
                }
            },
            handleReset () {
                try{
                    if(this.$refs.resetForm.validate()){
                        this.$store.dispatch(REQUEST_RESET_PASSWORD, this.formData)
                        this.submitted = true;
                      }
               
                }catch(error){
                    this.$refs.resetForm.validate()
                }
            },
            
        }
        
    }
</script>