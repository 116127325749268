import Vue from "vue";

//import axios from "axios";


export const S3Service = {
  upload(presignedPostData, file, onUploadProgress) {
    let formData = new FormData();
    Object.keys(presignedPostData.fields).forEach(key => {
      formData.append(key, presignedPostData.fields[key]);
    });
    var s3Instance = Vue.axios.create();
    delete s3Instance.defaults.headers.common['Authorization'];
    console.log(file);
    formData.append("file", file);
    return s3Instance.post(presignedPostData.url, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress,
    });
  }
}
