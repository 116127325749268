export const FETCH_END = "setFiles";
export const FETCH_START = "setLoading";
export const FILE_UPLOADED = "fileUploaded";
export const FILE_SELECTED = "fileSelected"
export const FETCH_PREDS_END = "setPredictions";
export const FETCH_PREDS_START = "setLoadingPredictions";
export const FETCH_PRED_END = "setPrediction";
export const FETCH_PRED_START = "setLoadingPrediction";
export const FETCH_SIGNED_URL = "fetchSignedURL";
export const FETCH_SIGNED_URL_END = "fetchSignedURLEnd";
export const SET_LOGIN = "setLogin";
export const SET_USER = "setUser";
export const DELETE_UPLOAD_START = 'deleteUploadStart';
export const DELETE_UPLOAD_END = 'deleteUploadEnd';
export const SET_SIGNUP = "setSignup";
export const UPLOAD_END = "uploadEnd";
export const UPLOAD_START = "uploadStart";
export const SET_REQUEST_RESET_PASSWORD = "requestResetPassword";
export const SET_UPDATE_PASSWORD = "updatePassword";
export const FETCH_BALANCE_END = "setBalance";
export const FETCH_BALANCE_START = "setBalanceLoading";
export const SELECT_PRED_START = "selectPredStart";
export const SELECT_PRED_END = "selectPredEnd";
export const SET_LOGIN_FAILED = "loginFailed";
export const SET_LOGIN_MAGIC_LINK = "setLoginMagicLink";