import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

import { API_URL } from "@/common/config";

import { getToken } from "@/common/jwt.service"

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },

  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[Minifig Finder] ApiService ${error}`);
    });
  },

  get(resource) {
    return Vue.axios.get(`${resource}`).catch(error => {
      throw new Error(`[Minifig Finder] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  post_headers(resource, params, config) {
    return Vue.axios.post(`${resource}`, params, config);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[Minifig Finder] ApiService ${error}`);
    });
  }
};

export default ApiService;


export const FilesService = {
  create(file) {
    let formData = new FormData();

    formData.append("image", file);

    return ApiService.post_headers("files", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },

  get() {
    ApiService.setHeader()
    return ApiService.get("uploads");
  },

  generateUrl(upload) {
    if (upload !== undefined) {
      let slug = upload['id']
      return ApiService.post(`uploads/${slug}`)
    } else {
      return ApiService.post('uploads');
    }
  },

  delete(upload) {
    if (typeof upload !== "object") {
      throw new Error(
        `[Minifig Finder] Fo;esService.delete() upload slug required to delete uploads`
      )
    }
    let slug = upload['id']
    return ApiService.delete(`uploads/${slug}`);
  },

  getPredictions(slug) {
    ApiService.setHeader()
    if (typeof slug !== "string") {
      throw new Error(
        `[Minifig Finder] PredictionService.get() prediction slug required to fetch predictions`
      )
    }
    return ApiService.get(`uploads/${slug}/predictions`)
  }
}

export const PredictionService = {
  get(slug) {
    if (typeof slug !== "string") {
      throw new Error(
        `[Minifig Finder] PredictionService.get() prediction slug required to fetch predictions`
      )
    }
    return ApiService.get(`predictions/${slug}`);
  },
  put(slug, content) {
    if (typeof slug !== "string") {
      throw new Error(
        `[Minifig Finder] PredictionService.get() prediction slug required to put predictions`
      )
    }
    return ApiService.put(`predictions/${slug}`, content);
  }
}

export const BalanceService = {
  get() {
    return ApiService.get(`balance`);
  }
}

