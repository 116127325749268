import { FilesService, PredictionService } from "@/common/api.service";
import { FETCH_PREDICTIONS, FETCH_PRED, SELECT_PREDICTION } from "./actions.type";

import {
  FETCH_PREDS_START,
  FETCH_PREDS_END,
  FETCH_PRED_START,
  FETCH_PRED_END,
  SELECT_PRED_START,
  SELECT_PRED_END,
} from "./mutations.type";


const state = {
  predictions: {},
  isLoading: true,
};

const getters = {
  getPredictions(state) {
    return state.predictions;
  },
  getSelected(state, predictionID, index) {
    if (state.predictions[predictionID].selected === index) {
      return true;
    }
    return false;
  },
};

const actions = {
  [FETCH_PREDICTIONS]({ commit, dispatch }, file) {
    commit(FETCH_PREDS_START);
    return FilesService.getPredictions(file)
      .then(({ data }) => {
        commit(FETCH_PREDS_END, data);
        for (let i = 0; i < data['predictions'].length; i++) {
          let pred = data['predictions'][i];
          dispatch(FETCH_PRED, pred.id);
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  [FETCH_PRED]({ commit }, pred) {
    commit(FETCH_PRED_START);
    return PredictionService.get(pred)
      .then(({ data }) => {
        commit(FETCH_PRED_END, data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  [SELECT_PREDICTION]({ commit }, selected) {
    commit(SELECT_PRED_START);
    let payload = {};
    payload['result'] = selected.classificationID;
    return PredictionService.put(selected.predictionID, payload)
      .then(() => {
        commit(SELECT_PRED_END, selected);
      })
      .catch(error => {
        throw new Error(error);
      });
  }
};

const mutations = {
  [SELECT_PRED_START](state) {
    state.isLoading = true;
  },
  [SELECT_PRED_END](state, selected) {
    let classificationID = selected.classificationID;
    let predictionID = selected.predictionID;

    for (let i = 0; i< state.predictions[predictionID]['classifications'].length; i++) {
      if (i === classificationID) {
        state.predictions[predictionID]['classifications'][i]['selected'] = true;
      } else { 
        state.predictions[predictionID]['classifications'][i]['selected'] = false;
      }
    }
    //state.predictions[predictionID]['classifications'][classificationID]['selected'] = true;
    state.predictions[predictionID]['result'] = classificationID;
    //state.predictions[predictionID] = cur;
    state.isLoading = false;
  },
  [FETCH_PREDS_START](state) {
    state.isLoading = true;
  },
  [FETCH_PREDS_END](state, predictions) {
    let x = {}
    for (let i = 0; i < predictions['predictions'].length; i++) {
      let pred = predictions['predictions'][i]
      x[pred.id] = {
        'location': pred.location,
      }
    }
    state.predictions = x;
    state.isLoading = false;
  },
  [FETCH_PRED_START](state) {
    state.isLoading = true;
  },
  [FETCH_PRED_END](state, prediction) {
    let pred = prediction['prediction'];
    for (let i = 0; i < pred['classifications'].length; i++) {
      if (i === pred['result']) {
        pred['classifications'][i]['selected'] = true;
      } else {
        pred['classifications'][i]['selected'] = false;
  
      }
    }
    state.predictions[pred.id] = pred;
    state.isLoading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};