<template>
  <div>
    <LogoutButton v-if="getLogin" />
    <LoginButton v-else />
  </div>
</template>

<script>
import LoginButton from "@/components/LoginButton";
import LogoutButton from "@/components/LogoutButton";
import { mapGetters } from "vuex";
export default {
  name: "AuthenticationButton",
  components: { LogoutButton, LoginButton },
  computed: {
    ...mapGetters(["getLogin"])
  },
  mounted(){
    this.getLogin
  },
};
</script>