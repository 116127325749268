export const FETCH_FILES = "fetchFiles";
export const CREATE_FILE = "createFile";
export const SELECT_FILE = "selectFile";
export const UPLOAD_SELECTED_FILE = "uploadSelectedFile";
export const FETCH_PREDICTIONS = "fetchPredictions";
export const FETCH_PRED = "fetchPrediction";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const DELETE_UPLOAD = "deleteUpload";
export const SIGNUP = "signup";
export const CHECK_USER = "checkUser";
export const REQUEST_RESET_PASSWORD = "requestResetPassword";
export const UPDATE_PASSWORD = "updatePassword";
export const FETCH_BALANCE = "fetchBalance";
export const SELECT_PREDICTION = "selectPrediction";
export const LOGIN_MAGIC_LINK = "loginMagicLink";