<template>
  <v-card :style="isShowSelected() ? 'border: 3px solid green;' : 'border: 1px solid white;'">
    <v-img
      :src="`https://cdn.rebrickable.com/media/thumbs/parts/elements/${prediction['element_id']}.jpg/250x250p.jpg`" width="100%">
      <v-app-bar
        flat
        color="rgba(0, 0, 0, 0)">
        <v-spacer></v-spacer>
          <v-btn
            color="light-green"
            icon
            @click="selectPrediction"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
      </v-app-bar>
    </v-img>
    <v-card-title primary-title>
    {{ prediction['part_num'] }}
    {{ prediction['catalog_id'] }}
    </v-card-title>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { SELECT_PREDICTION } from "../store/actions.type";
export default {
  name: "PredictionListItem",
  components: {
    
  },
  props: {
    prediction: { type: Object, required: true },
    index: { type: Number, required: true },
    classificationID: { type: String, required: true },
  },
  computed: {
    ...mapGetters(["getSelected"])
  },
  mounted() {
  },
  methods: {
    isShowSelected() {
      return this.prediction.selected;
    },
    selectPrediction() {
      this.$store.dispatch(SELECT_PREDICTION, {predictionID: this.classificationID, classificationID: this.index });
    }
    
  }
};
</script>