<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      :clientReferenceId="clientReferenceId"
      :customerEmail="customerEmail"
      @loading="v => loading = v"
    />
    <v-btn @click="submit">{{balance}} credits</v-btn>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { STRIPE_PUBLISHABLE_KEY } from "@/common/config";
import { getUser } from "@/common/jwt.service"

export default {
  name: "PayButton",
  components: {
    StripeCheckout,
  },
  props: {
    balance: { type: Number, required: true }
  },
  data () {
    this.clientReferenceId = getUser().id;
    this.customerEmail = getUser().email;
    this.publishableKey = STRIPE_PUBLISHABLE_KEY;
    return {
      loading: false,
      lineItems: [
        {
          price: 'price_1K8WoIFfKgYY6HYCckAMnU8y', // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: 'https://minifigfinder.com/dashboard',
      cancelURL: 'https://minifigfinder.com/dashboard',
      //successURL: 'https://10.2.0.205:8081/dashboard',
      //cancelURL: 'https://10.2.0.205:8081/dashboard',
    };
  },
  // You will be redirected to Stripe's secure checkout page
  methods: {
    submit () {
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>