import Vue from "vue";
import VueRouter from "vue-router";
import VueGtag from "vue-gtag";

import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Prediction from "../views/Prediction.vue";
import ForgotPassword from "../views/ForgotPassword.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/resetPassword",
    name: "Reset Password",
    component: ForgotPassword,
  },
  {
    path: "/dashboard",
    meta: { requiresAuth: true },
    name: "Dashboard",
    component: Dashboard,
  }, 
  {
    path: "/prediction/:slug",
    meta: { requiresAuth: true },
    name: "Prediction",
    component: Prediction,
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

Vue.use(VueGtag, {
    config: { id: 'G-MH79GDE6KE' }
}, router);

function loggedIn() {
  if (localStorage.getItem("supabase.auth.token") != null) {
    return true;
  } else {
    return false;
  }
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!loggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (loggedIn()) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router