<template>
  <div><PredictionList :uploadID="slug" type="all" /></div>
</template>
<script>
import { mapGetters } from "vuex";
import PredictionList from "@/components/PredictionList";
//import { FETCH_PREDICTIONS } from "@/store/actions.type";
//import store from "@/store";
export default {
  name: "dashboard",
  components: {
    PredictionList
  }, 
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  //beforeRouteEnter(to, from, next) {
  //  Promise.all([
  //    store.dispatch(FETCH_PREDICTIONS, to.params.slug)
  //  ]).then(() => {
  //    next();
  //  })
  //},
  computed: {
    ...mapGetters(["predictions"])
  },

};
</script>