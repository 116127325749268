import { FilesService, BalanceService } from "@/common/api.service";
import { S3Service } from "@/common/s3.service";
import { DELETE_UPLOAD, FETCH_FILES, FETCH_BALANCE, SELECT_FILE, UPLOAD_SELECTED_FILE } from "./actions.type";

import {
  FETCH_START,
  FETCH_END,
  FETCH_BALANCE_START,
  FETCH_BALANCE_END,
  FETCH_SIGNED_URL,
  FETCH_SIGNED_URL_END, 
  FILE_UPLOADED,
  DELETE_UPLOAD_START,
  DELETE_UPLOAD_END,
  UPLOAD_START,
  UPLOAD_END,
} from "./mutations.type";


const state = {
  selectedFiles: undefined,
  files: [],
  presignedURL: undefined,
  isLoading: true,
  message: "",
  progress: 0,
  isUploading: false,
  balance: undefined,
};

const getters = {
  filesCount(state) {
    return state.filesCount;
  },
  uploadedFiles(state) {
    return state.uploadedFiles;
  },
  selectedFiles(state) {
    return state.selectedFiles;
  },
  presignedURL(state) {
    return state.presignedURL;
  },
  files(state) {
    return state.files;
  },
  isLoading(state) {
    return state.isLoading;
  },
  message(state) {
    return state.message;
  },
  progress(state) {
    return state.progress;
  },
  isUploading(state) {
    return state.isUploading;
  },
  balance(state) {
    return state.balance;
  },
};

const actions = {
  [FETCH_FILES]({ commit }) {
    commit(FETCH_START);
    return FilesService.get()
      .then(({ data }) => {
        commit(FETCH_END, data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  [FETCH_BALANCE]({ commit }) {
    commit(FETCH_BALANCE_START);
    return BalanceService.get()
      .then(({ data }) => {
        commit(FETCH_BALANCE_END, data);
      })
      .catch(error => {
        throw new Error(error)
      });
  },
  [SELECT_FILE]({ dispatch, commit }, event) {
    console.log(event);
    let fileID = event.upload
    let file = event.file
    commit(FETCH_SIGNED_URL, file);
    return FilesService.generateUrl(fileID)
      .then(({ data }) => {
        commit(FETCH_SIGNED_URL_END, data);
        return dispatch(UPLOAD_SELECTED_FILE)
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  [UPLOAD_SELECTED_FILE]({ commit }) {
    commit(UPLOAD_START);
    return S3Service.upload(state.presignedURL, state.selectedFiles, (event) => {
      state.progress = Math.round((100 * event.loaded) / event.total)
    })
      .then(({ data }) => {
        commit(UPLOAD_END);
        commit(FILE_UPLOADED, data);
      })
      .catch(error => {
        throw new Error(error)
      });
  },
  [DELETE_UPLOAD]({commit}, fileID, file) {
    commit(DELETE_UPLOAD_START, file);
    return FilesService.delete(fileID)
      .then(({ data }) => {
        commit(DELETE_UPLOAD_END, data);
      })
      .catch(error => {
        throw new Error(error);
      });
  }
};

const mutations = {
  [DELETE_UPLOAD_START](state) {
    state.isLoading = true;
  },
  [DELETE_UPLOAD_END](state) {
    state.isLoading = false;
  },
  [FETCH_START](state) {
    state.isLoading = true;
  },
  [FETCH_END](state , files) {
    state.files = files['uploads'];
    state.isLoading = false;
  },
  [FETCH_BALANCE_START](state) {
    state.isFetchingBalances = true;
  },
  [FETCH_BALANCE_END](state, balance) {
    state.balance = balance['balance'];
    state.isFetchingBalances = false;
  },
  [UPLOAD_START](state) {
    state.isUploading = true;
  },
  [UPLOAD_END](state) {
    state.isUploading = false;
  }, 
  [FETCH_SIGNED_URL](state, file) {
    state.selectedFiles = file
  },
  [FETCH_SIGNED_URL_END](state, data) {
    state.presignedURL = data['upload_url']
  },
  [FILE_UPLOADED](state, data) {
    console.log(data);
    state.presignedURL = undefined
    state.selectedFiles = "" 
    state.message = "Successfully Uploaded"
    state.progress = 0
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};