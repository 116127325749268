<template>
    <v-btn
      @click.prevent="deleteFile"
      icon>
      <v-icon>mdi-delete</v-icon>
    </v-btn>
</template>


<script>
import { DELETE_UPLOAD } from "../store/actions.type";
export default {
  name: "DeleteUpload",
  components: {
  },
  data() {
    return {

    };
  },
  props: {
    fileID: {
      required: false
    }
  },
  computed: {
  },
  methods: {
    deleteFile() {
      this.$store.dispatch(DELETE_UPLOAD, this.fileID);
    }
  },
  mounted() {
  }
};
</script>









